import TestImage from "../assets/overflow/gear_black.png";

//[]=================[ ADD MORE ERRORS HERE ]=================[]
export const errorContent = [
    //------------------------------
    //game-engine id: 0
    //description: 
    ["Fehler 1", TestImage],
    
    //------------------------------
    //game-engine id: 1
    //description: 
    ["Fehler 2", TestImage],

    //------------------------------
    //game-engine id: 2
    //description: 
    ["Fehler 3", TestImage],

    //------------------------------
    //game-engine id: 3
    //description: 
    ["Fehler 4", TestImage],

    //------------------------------
    //game-engine id: 4
    //description: 
    ["Fehler 5", TestImage],

    //------------------------------
    //game-engine id: 5
    //description: 
    ["Fehler 6", TestImage],

    //------------------------------
    //game-engine id: 6
    //description: 
    ["Fehler 7", TestImage],

        //------------------------------
    //game-engine id: 7
    //description: 
    ["Fehler 8", TestImage],
]